import 'native-promise-only';

import "./base.scss";
import companyMembership from './components/company-membership';
import "./components/header";
import navigation from "./components/navigation";
import hero from "./components/hero";
import "./components/promo-block";
import "./components/text-block-sidebar";
import "./components/academy-promo-block";
import "./components/insight-card";
import "./components/three-column-container";
import gridGuide from "./components/grid-guide";
import "./components/news-block";
import "./components/download-block";
import "./components/category-card";
import "./components/news-card";
import footer from "./components/footer";
import textWithCta from "./components/text-with-cta";
import "./components/embedded-video";
import "./components/image-block";
import search from './components/search';
import './components/headerSearch';

function run() {
  gridGuide();
  navigation();
  footer();
  hero();
  search();
  textWithCta();
  companyMembership();
}

window.addEventListener('DOMContentLoaded', () => {
  if (!Array.prototype.keys || !Array.from || !Array.prototype.includes) {
    Promise.all([
      System.import('core-js/features/array/from'),
      System.import('core-js/features/array/keys'),
      System.import('core-js/features/array/includes'),
      System.import('core-js/features/object/assign'),
      System.import('core-js/web/dom-collections'),
    ]).then(run);
  } else {
    run();
  }
});