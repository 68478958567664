import "./company-membership.scss";

export default function () {
  const el = document.querySelectorAll('.node-cbc-company-work-example');
  if (!el) {
    return;
  }

  Array.prototype.forEach.call(el, function (acc) {
    const accordions = acc.querySelectorAll('.node-cbc-company-work-example-container-header');
    Array.prototype.forEach.call(accordions, function (accordion) {
      accordion.addEventListener('click', () => {
        accordion.classList.toggle('accordion--active');
        const panel = accordion.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    });
  });
}
