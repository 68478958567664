import "./navigation.scss";
import "./ciob-navigation.tpl.php";

export default function() {
    const el = document.querySelector('.navigation__list');

    if (!el) {
        return;
    }

    const mobileNav = document.querySelector('.navigation__mobile-nav');
    const navSearch = document.querySelector('.navigation__search');
    mobileNav.addEventListener('click', () => {
        mobileNav.classList.toggle('navigation__mobile-nav--active');
        el.classList.toggle('navigation__list--open');
        navSearch.classList.toggle('navigation__search--open')
    });

    console.log("el is " + el);
    const lis = el.querySelectorAll('.navigation__list > ul > li');
    Array.prototype.forEach.call(lis, item => {
        console.log("item is" + item);
        item.setAttribute('aria-expanded', 'false');
        item.addEventListener('click', () => {
            const activeLis = el.querySelectorAll('.navigation__list > ul > li.active')
            Array.prototype.forEach.call(activeLis, openItem => {
                if (openItem !== item) {
                    openItem.classList.remove('active');
                    openItem.setAttribute('aria-expanded', 'false');
                }
            });
            item.classList.toggle('active');
            if (item.classList.contains('active')) {
                item.setAttribute('aria-expanded', 'true');

                const fn = e => {
                    if (!item.contains(e.target)) {
                        item.click();
                        document.removeEventListener('click', fn);
                    }
                };
                document.addEventListener('click', fn);
            } else {
                item.setAttribute('aria-expanded', 'false');
            }
        });

        const spans = el.querySelectorAll('.navigation__list > ul > li > span');
        Array.prototype.forEach.call(spans,title => {
            title.tabIndex = 0;
        });

        item.addEventListener('keydown', e => {
            if (e.keyCode === 13) {
                item.click();
            }
        });
    });

    const searchButton = document.querySelector('.navigation__search__toggle');
    const searchBox = document.querySelector('.navigation__search__box');

    searchButton.addEventListener('click', () => searchBox.focus());
    searchBox.addEventListener('focus', () =>
        navSearch.classList.add('navigation__search--open')
    );
    searchBox.addEventListener('blur', () =>
        navSearch.classList.remove('navigation__search--open')
    );

    const close = document.querySelector('.navigation__search--close');
    close.addEventListener('click', () => {
        close.blur();
    });


}