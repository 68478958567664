import "regenerator-runtime/runtime";
import "./search.scss";
import './ciob-search.tpl.php';
import instantsearch from "instantsearch.js";
import { configure, searchBox, refinementList, stats, hits, pagination } from "instantsearch.js/es/widgets";

export default () => {
  const siteSearch = document.querySelector(".search");

  if (!siteSearch) {
    return;
  }

  const order = {
    "Course": 1,
    "Blog post": 2,
    Fees: 3,
    "CPC Company": 4,
  };

  const appID = Drupal.settings.algoliasearch.applicationID;
  const apiKey = Drupal.settings.algoliasearch.apiKey;
  const indexName = Drupal.settings.algoliasearch.indexName;
  const initialSearch = Drupal.settings.algoliasearch.initialSearch;

  const mobileQuery = window.matchMedia("(max-width: 768px)");
  let snippet;
  if (mobileQuery.matches) {
    snippet = ["text:5"];
  } else {
    snippet = ["text:20"];
  }

  (async () => {
    const search = await instantsearch({
      indexName,
      searchClient: algoliasearch(appID, apiKey),
    });


    // Custom widget to set the inital query from text provided by drupal.
    search.addWidget({
      init: function(options) {
        if(initialSearch) {
          options.helper.setQuery(initialSearch);
        }
      }
    });

    search.addWidget(
      configure({
        hitsPerPage: 10,
        attributesToSnippet: snippet,
        snippetEllipsisText: "…",
        restrictHighlightAndSnippetArrays: true,
        facetingAfterDistinct: true,
      })
    );

    search.addWidget(
      searchBox({
        container: ".search__search-box",
        placeholder: "Search CIOB",
        magnifier: false,
        reset: false,
      })
    );

    search.addWidgets([
      refinementList({
        container: "#brand-list",
        attribute: "type",
        templates: {
          item: `
              <a href="{{url}}" style="{{#isRefined}}font-weight: bold{{/isRefined}}">
                <span>{{label}} ({{count}})</span>
              </a>
            `,
        },
        sortBy: function(a, b) {
          return order[a.name] > order[b.name] ? 1 : -1;
        },
      }),
    ]);

    search.addWidgets([
      stats({
        container: ".search__stats",
        templates: {
          text({ hitsPerPage, page, nbHits, hasManyResults, hasOneResult }) {
            const resultsFrom = hitsPerPage * page + 1;
            const resultsTo = Math.min(resultsFrom + hitsPerPage - 1, nbHits);
            let count = "";
            if (hasManyResults) {
              count += `${nbHits}`;
            } else if (hasOneResult) {
              count += `1 result`;
            } else {
              count += `no result`;
            }
            return `<span class="search__stats__hits">${resultsFrom}-${resultsTo}</span> of <span class="search__stats__hits">${count}</span> results found`;
          },
        },
      }),
    ]);

    search.addWidgets([
      hits({
        container: ".search__hits",
        transformItems(items) {
          return items.map(item => ({
            ...item,
            date: new Date(item.date * 1000).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            }),
          }));
        },
        templates: {
          empty: function(context) {
            return (
              '<h3>Nothing to see here</h3><div>Sorry, we couldn’t find anything for "' +
              context.query +
              '". Please try another search or return <a href="/">home</a>.</div>'
            );
          },
          item: hit => {
            const { type, date, title, url, source } = hit;
            if (type === "news") {
              return `<div class="search-card search-card--${type}">
                <div class="search-card__meta">
                  <div class="search-card__label">${type}</div>
                  <div class="search-card__date">${date}</div>
                </div>
                <div class="search-card__title">
                  <h5>${title}</h5>
                </div>
                <div class="search-card__description">
                  <p>
                    ${instantsearch.snippet({ attribute: "text", hit })}
                  </p>
                </div>
                <div class="search-card__button">
                  <a href="${url}" target="_blank"></a>
                </div>
              </div>`;
            }
            return `<div class="search-card search-card--${type} search-card--source-${source}">
                <div class="search-card__meta">
                  <div class="search-card__label">${type}</div>
                  <div class="search-card__date">${date}</div>
                </div>
                <div class="search-card__title">
                  <h5>${title}</h5>
                </div>
                <div class="search-card__description">
                  <p>
                    ${instantsearch.snippet({ attribute: "text", hit })}
                  </p>
                </div>
                <div class="search-card__button">
                <a href="${url}" aria-label="View ${title}"></a>
                </div>
              </div>`;
          },
        },
      }),
    ]);

    search.addWidget(
      pagination({
        container: ".search__pagination",
        padding: 2,
        scrollTo: true,
        showFirst: false,
        showLast: false,
        templates: {
          previous: "Previous",
          // previous: Drupal.t('BACK'),
          next: "Next",
          // next: Drupal.t('NEXT')
        },
      })
    );

    search.start();
  })();
};
