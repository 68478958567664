import "./footer.scss";
import "./ciob-footer.tpl.php";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons/faYoutubeSquare";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons/faInstagramSquare";

library.add(faFacebookSquare, faLinkedin, faTwitterSquare, faYoutubeSquare, faInstagramSquare);

export default function() {
  const nodes = document.getElementsByClassName("footer__social");

  //Ie doesn't support iterators
  for (var i = 0; i < nodes.length; i++) {
    dom.i2svg({ node: nodes.item(i) });
  }
}
