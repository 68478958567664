import "./hero.scss";
import "./ciob-hero.tpl.php";

export default function() {
  const el = document.querySelector(".hero");

  if (!el) {
    return;
  }

  window.addEventListener("load", showPage);
  function showPage() {
    document.body.classList.add("g--load-transition");
  }

  // Parallax
  function simpleParallax(element, item, percentage) {
    const scrollPosition = window.scrollY || window.pageYOffset;
    const timeline = scrollPosition / element.clientHeight;
    if (scrollPosition >= 0 && timeline < 1) {
      const parallaxAmount = timeline * percentage;
      item.style.transform = `translateY(${parallaxAmount}%)`;
    }
    if (scrollPosition < 1) {
      item.style.transform = `translateY(0)`;
    }
  }

  // Request animation frame
  const raf =
    window.requestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    };

  const handler = () =>
    raf(() => {
      const heroContent = document.querySelector(".hero__content");
      const heroMedia = document.querySelector(".hero__media");
      if (window.matchMedia("(min-width: 768px)").matches) {
        simpleParallax(el, heroContent, -100);
        simpleParallax(el, heroMedia, 20);
      }
    });

  //Only perform parallax when hero has an image. Its hard to tell without one.
  if(document.querySelector(".hero:not(.hero--no-image)")) {
    handler();
    window.addEventListener("scroll", handler);
  }
}
